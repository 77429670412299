<template>
  <el-drawer
    ref="ibDrawer"
    class="ib-drawer"
    :visible="visible"
    :direction="direction"
    :size="size"
    :with-header="false"
    @open="$emit('open')"
    @opened="$emit('opened')"
    @close="$emit('close')"
    @closed="$emit('closed')"
  >
    <slot />
  </el-drawer>
</template>

<script>
const DIRECTIONS = ['rtl', 'ltr', 'ttb', 'btt']

export default {
  name: 'IbDrawer',

  props: {
    visible: {
      type: Boolean,
      default: false
    },

    size: {
      type: String,
      default: '220px'
    },

    direction: {
      type: String,
      validator: value => {
        return DIRECTIONS.includes(value)
      },
      default: 'rtl'
    }
  }
}
</script>

<style scoped lang="scss">
.ib-drawer {
  ::v-deep {
    .el-drawer {
      //width: $ib-sidebar-width !important;

      &.btt,
      &.ttb {
        width: unset !important;
        height: $ib-sidebar-width !important;
      }
    }
  }
}
</style>
